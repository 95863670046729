import React, {useState, useEffect} from "react"
import { Link } from "gatsby"
import { motion, useAnimation } from "framer-motion"
import styled from "styled-components"
import Input from "components/common/input"
import ArrowRight from "components/icons/arrowRight"
import Sync from "components/icons/sync"
import Envolope from "components/icons/envolope"
import ErrorMessage from "components/common/errorMessage"
import StyledBtn from "styles/button"
import Cgu from "components/common/cgu"
import Select, {components} from 'react-select'
import Trash from "components/icons/trash"
import CreatableSelect from 'react-select/creatable';
import XMark from "components/icons/xMark";
import { algorithmKeyOptions, defaultTexts, defaultContext, defaultLabels } from "./defaultValues"

const StyledForm = styled.form`
  background: white;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  min-height: 23rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  .form__body {
    padding: 1.5rem;
    .control {
      margin-bottom: 1rem;
      position: relative;

      .trash-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
    .label {
      color: ${({ theme }) => theme.colors.zodiacLight};
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 0.55rem;
    }

    .subtitle {
      color: ${({ theme }) => theme.colors.tertiary};
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 1.2rem;
    }
    .alert {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      text-align: center;
      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        a:first-of-type {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        a {
          width: 100%;
        }
        margin-top: 0.7rem;
      }
      .logo {
        height: 3.56rem;
        width: 3.56rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E7F0FF;
        border-radius: 0.22rem;
        margin-bottom: 1rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        .buttons {
          flex-direction: row;
          a:first-of-type {
            margin-right: 1rem;
            margin-bottom: 0;
          }
          a {
            width: unset;
          }
        }
      }
    }
  }
  .form__footer {
    padding: 1.3rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EBEFF9;
  }
`

const StyledButton = styled(StyledBtn).attrs({
  as: "button"
})`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #9F7AEA;
  border: none;
  cursor: pointer;
  min-width: 10rem;
`

const StyledButtonLink = styled(StyledBtn)`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #9F7AEA;
  border: none;
  cursor: pointer;
`

const StyledButtonLinkWhite = styled(StyledButtonLink)`
  color: #9F7AEA;
  background: white;
  border: 1px solid #9F7AEA;
`
const StyledButtonAdd = styled(StyledButton).attrs({ as: "button" })`
  color: #008ED2;
  background: white;
  border: 1px solid #008ED2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SelectedQuestions = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const SelectedLocation = styled.div`
  display: flex;
  align-items: center;
  background-color: #eaffff; 
  color: #1dafb6;
  border: 1px solid #1dafb6;
  border-radius: 4px; 
  padding: 4px 8px; 
  gap: 4px;
  font-size: 14px;
`;

const RemoveButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Form = props => {
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState("")
  const [algorithmKey, setAlgorithmKey] = useState(algorithmKeyOptions[1])
  const [texts, setTexts] = useState(defaultTexts);
  const [labels, setLabels] = useState(defaultLabels);
  const [context, setContext] = useState(defaultContext);
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState(false)


  const dControls = useAnimation()
  const eControls = useAnimation()
  const rControls = useAnimation()

  const handleTextChange = (value, index) => {
    const newTexts = [...texts];
    newTexts[index] = value;
    setTexts(newTexts);
  };

  const handleTextRemove = index => {
    const newTexts = texts.filter((_, tIndex) => tIndex !== index);
    setTexts(newTexts);
  };

  // Handle the creation of new labels
  const handleCreateLabel = (inputValue) => {
    const newLabel = { value: inputValue, label: inputValue };
    setLabels([...labels, newLabel]);
  };

  // Remove a label
  const handleRemoveLabel = labelToRemove => {
    setLabels(labels.filter(label => label !== labelToRemove));
  };

  // Handle context change
  const handleChangeContext = (event) => {
    setContext(event.target.value);
  };

  const CustomValueContainer = ({ children, ...props }) => {
    const hasInputValue = !!props.selectProps.inputValue;

    return (
      <components.ValueContainer {...props}>
        {!hasInputValue && (
          <components.Placeholder {...props}>
            {props.selectProps.placeholder}
          </components.Placeholder>
        )}
        {React.Children.map(children, child =>
          child && child.type !== components.Placeholder ? child : null
        )}
      </components.ValueContainer>
    );
  };

  const customComponents = {
    MultiValue: () => null,
    ValueContainer: CustomValueContainer
  };

  useEffect(() => {
    if(props.textTaggingResult.s) {
      setTexts(props.textTaggingResult.payload.texts);
      setAlgorithmKey(algorithmKeyOptions.find(option => option.value === props.textTaggingResult.payload.algorithm_key));
      setContext(props.textTaggingResult.payload.context);
      setLabels(props.textTaggingResult.payload.labels.map(label => ({ value: label, label: label })));
    }

  }, [props.textTaggingResult])

  console.log("props.textTaggingResult", props.textTaggingResult)

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const StepTransitionSequence = async () => {
        await dControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await eControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await rControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        // Animate underlining to hover state
    }
    StepTransitionSequence()
  }, [eControls, dControls, rControls, step])

  useEffect(() => {
    if(!firstLoad) {
      console.log('props.response',props.response)
      if(props.response?.s) {
        setStep(3)
        setError(false)
      }
      if(props.response.f) {
        setStep(3)
        setError(true)
      }
    }
    return () => {
      setFirstLoad(false)
    };
  }, [props.response])
  
  useEffect(() => {
    setStep(1)
  }, [])

  return (
    <StyledForm
      color="#9F7AEA"
      onSubmit={e => {
          e.preventDefault();
          if(step === 1) {
            setStep(2)
          } else {
            if(algorithmKey && texts) {
              props.postTextTagging({ 
                email: email, 
                algorithm_key: algorithmKey.value, 
                texts: texts, 
                context: context,
                labels: labels.map(label => label.value), 
                marketing_data: props.params, 
                referrer: document.referrer 
              })
            }  
          }
        }
      }
    >
      <div className="form__body">
        { step === 1 && (
          <motion.div
            className="control"
            initial={{ opacity: 0, x: 80 }}
            animate={dControls}
          >
            <div className="label">Enter text to tag</div>
            <div className="subtitle">
              Enter  an unstructured text, and our library of AI algorithms will predict the most likely tags for it.
            </div>
            <div className="control">
              <div className="label">AI Algorithm </div>
              <div className="subtitle">
                AI tagging algorithm you want to apply to the input text.
              </div>
              <Select
                isSearchable={false}
                options={algorithmKeyOptions}
                simpleValue
                name="algorithmKey"
                value={algorithmKey}
                onChange={(value) => setAlgorithmKey(value)}
                clearable={false}
              />
            </div>
            {algorithmKey.value === "tagger-hrflow-labels" && (
              <div className="control">
                <div className="label">Context</div>
                <div className="subtitle">
                  A context for given labels if algorithm_key="tagger-hrflow-labels".
                </div>
                <Input 
                  changed={handleChangeContext}
                  type="textarea"
                  placeholder="Enter context here"
                  value={context}
                  content="context"
                />
              </div>
            )} 
            {algorithmKey.value === "tagger-hrflow-labels" && (
              <div className="control">
                <div className="label">Labels</div>
                <div className="subtitle">
                 List of output tags if algorithm_key ="tagger-hrflow-labels".
                </div>
                <CreatableSelect
                  name="labels"
                  isClearable={false}
                  noOptionsMessage={() => "Type and press enter"}
                  isMulti
                  options={labels}
                  onChange={setLabels}
                  onCreateOption={handleCreateLabel}
                  value={labels}
                  placeholder="Create labels"
                  components={customComponents}
                />
                <SelectedQuestions>
                  {labels.map((label, index) => (
                    <SelectedLocation key={index}>
                      <RemoveButton onClick={() => handleRemoveLabel(label)}>
                        <XMark />
                      </RemoveButton>
                      {label.label}
                    </SelectedLocation>
                  ))}
                </SelectedQuestions>
              </div>
            )}
            <div className="control">
              <div className="label"> Texts</div>
              <div className="subtitle">
                Enter a text or a list of texts. Each text can be: the full text of a Job, a Resume, a Profile, an experience, a Job and more.
              </div>
              {texts.map((text, index) => (
                <div  className="control">
                  <Input 
                    changed={event => handleTextChange(event.target.value, index)}
                    type="textarea"
                    placeholder="Enter text here"
                    value={text}
                    content="text"
                  />
                  {texts.length > 1 && (
                    <Trash
                      color="#EF4444"
                      onClick={() => handleTextRemove(index)}   
                      className="trash-icon"
                    />
                  )}
                </div>
              ))}
              <StyledButtonAdd
                  onClick={() => {
                  setTexts([...texts, ""]);
                }}
                type="button"
              >
                <span>+ ADD NEW TEXT</span>
              </StyledButtonAdd>
            </div>
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            className="control"
            initial={{ opacity: 0, x: 80 }}
            animate={eControls}
          >
            <div className="label">Enter your work email</div>
            <div className="subtitle">
              In order to receive your result, please enter your work email.
            </div>
            <Input 
              changed={event => setEmail(event.target.value)}
              type="input"
              placeholder="Enter your email"
            />
            <Cgu />

          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            className="alert"
            initial={{ opacity: 0, x: 80 }}
            animate={rControls}
          >
            { error ?
              (
                <ErrorMessage
                  title="Something went wrong"
                  subtitle="Please retry again later."
                  type="error"
                  min="true"
                />
              )
              :
              (
                <>
                  <div className="logo">
                    <Envolope color="#9F7AEA" />
                  </div>
                  <div className="label">We sent your results on your email address</div>
                  <div className="subtitle">
                    Please click on the link in the email to access your results
                  </div>
                </>
              )
            }
            <div className="buttons">
                <StyledButtonLinkWhite
                  to="/text-analysis/tagging/"
                  onClick={() => setStep(1)}
                >
                  <span className="mr-1">Try another text</span><Sync color="#9F7AEA"/>
                </StyledButtonLinkWhite>
                <StyledButtonLink
                    to="https://hrflow.ai/signup"
                    target="_blank"
                    rel="noreferrer"
                >
                  <span className="mr-1">Register now</span><ArrowRight color="#FFF" />
                </StyledButtonLink>                
              </div>
          </motion.div>
        )}
      </div>
      { step !== 3 && (
          <div className="form__footer">
            <StyledButton
              disabled={(!texts && !algorithmKey) || props.response.r}
              loading={props.response.r}
            >
                 { (props.response.r) ?
                  (
                    <div style={{display: 'flex', justifyContent: 'center', width:"100%"}}>
                      <span className="loader"></span>
                    </div>
                  )
                  :
                  (
                    <>
                      <span className="mr-1">Analyze</span><ArrowRight color="#FFF" />
                    </>
                  )
                }
            </StyledButton>
          </div>
        )
      }
    </StyledForm>
  )
}


export default Form
