export const algorithmKeyOptions = [
  { value: "tagger-hrflow-labels", label: "Tagger Hrflow Labels" }, // indeed
  { value: "tagger-hrflow-skills", label: "Tagger Hrflow Skills" },
  { value: "tagger-rome-category", label: "Tagger Rome Category" },
  { value: "tagger-rome-family", label: "Tagger Rome Family" },
  { value: "tagger-rome-jobtitle", label: "Tagger Rome Jobtitle" },
  { value: "tagger-rome-subfamily", label: "Tagger Rome Subfamily" },
]

export const defaultTexts = [
  "Data Insights Corp. is seeking a Senior Data Scientist for a contract-to-direct position. You will be responsible for designing and implementing advanced machine learning algorithms and playing a key role in shaping our data science initiatives. The CDI arrangement offers a pathway to a full-time role",
  "DataTech Solutions is hiring a Data Scientist for a fixed-term contract of 12 months. You will work on various data analysis and modeling projects and assisting in short-term projects; with the possibility of extension or permanent roles"
]

export const defaultContext = "The CDI is a Contrat à Durée Indeterminée - essentially an open-ended or permanent employment contract.  The CDD is a Contrat à Durée Determinée - a fixed-term or temporary employment contract.  These are the two most common types but by no means the only form of French employment contract. The contracts have to be drawn up by the employer, who must ensure that it's legally the correct type for the circumstances."

export const defaultLabels = [
  { value: "CDI", label: "CDI" },
  { value: "CDD", label: "CDD" },
]