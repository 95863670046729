import React from "react"
import styled from "styled-components"


const StyledContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1.3rem;
  font-size: 1rem;
`
const Ul = styled.ul`
  margin-top: 10px;
  list-style: none;
  padding-left: 0;
  width: 100%;
`

const Li = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
  font-size: 1rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #DFE7EF;
  color: ${({ theme }) => theme.colors.zodiacLight};
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`
const TagWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
`

const Title = styled.div`
  color: #183D63;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 1rem;
`
const Text = styled.div`
  max-height: 200px;
  overflow: auto;
  padding: 1rem;
  border: 1px solid #C6D7EA;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`

const PredectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const Predection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Tag = styled.span`
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  /* overflow: hidden; */
  margin-right: 1rem;
  display: block;
  /* @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;
  } */
`
const Label = styled.span`
  display: inline-block;
  background-color: #eaffff; 
  color: #1dafb6;
  border: 1px solid #1dafb6;
  border-radius: 4px; 
  padding: 4px 8px; 
  font-size: 14px;
  width: auto;
`

const Progress = styled.div`
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  background-color: #DFE7EF;
  border-radius: 0.5rem;
  width: 100%;
  margin-right: 2rem;

  .inner {
    background-color: #5628B4!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
  }
`

const Tagging = (props) => {
  return (
    <StyledContainer color="#9F7AEA">
      <Ul>
      {
        props.tagging?.map((item, index) => {
          const text = props.texts[index];
          return (
            <Li key={`tagging-${index}`}>
              <TextWrapper>
                <Title>Text</Title>
                <Text>{text}{text}{text}{text}{text}{text}{text}{text}{text}{text}</Text>
              </TextWrapper>
              <TagWrapper>
                <Title>Tagging</Title>
                {item?.predictions?.map((pre, i) => {
                  return (
                    <PredectionContainer key={i}>
                      {props.algorithmKey === "tagger-hrflow-labels" ?  
                        <div>
                          <Label>{props.labels[i]}</Label>
                        </div> 
                          : 
                        <Tag>{item.tags[i]}</Tag> 
                      }
                      <Predection>
                        <Progress>
                          <div
                            className="inner"
                            style={{ width: `${Math.round(pre*100)}%`}}
                          > 
                          </div>
                        </Progress>
                        <span>{Math.round(pre*100)}%</span>
                      </Predection> 
                       
                    </PredectionContainer>
                  )
                })}
              </TagWrapper>
            </Li>
          )
          }
        )
      }
      </Ul>
    </StyledContainer>
  )
}


export default Tagging;
