import React, {useEffect} from "react"
import styled from "styled-components"
import Spinner from 'components/common/spinner'
import Tagging from './tagging'
import ErrorMessage from "components/common/errorMessage"

const StyledContainer = styled.div`
  background: white;
  min-height: 20rem;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  .empty {
    height: 17rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const StyledSectionTitle = styled.div`
  color: #1A212D;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  margin: 1.5rem 1.5rem 0;
`

const Result = (props) => {
  useEffect(() => {
    const email = props.email && props.email.replace(/\s+/g, '+').toLowerCase();
    props.getTextTagging({algorithm_key: props.algorithmKey, job_key: props.jobKey, labels: props.labels, email: email})
    // scrollTo('#result')
  },[])

  if (props.textTaggingResult.r) {
    return (
      <StyledContainer color="#9F7AEA">
        <Spinner />
      </StyledContainer>
    )
  } else if (props.textTaggingResult.s && props.textTaggingResult.payload && props.textTaggingResult.payload.response.data) {
    return (
      <StyledContainer color="#9F7AEA">
        <StyledSectionTitle>Result</StyledSectionTitle>
        <Tagging
          tagging={props.textTaggingResult.payload.response.data}
          texts={props.textTaggingResult.payload.texts}
          algorithmKey={props.textTaggingResult.payload.algorithm_key}
          labels={props.textTaggingResult.payload.labels}
        />
      </StyledContainer>
    )
  } else {
    return (
      <StyledContainer color="#9F7AEA">
        <div className="empty">
          <ErrorMessage
            title="Something went wrong"
            subtitle="Please retry again later."
            type="error"
            min="true"
          />
        </div>
      </StyledContainer>
    )
  }
}

export default Result;