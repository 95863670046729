import React from 'react'
import PropTypes from "prop-types"

const XMark = ({ color, className, width, height, ...props }) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      strokeWidth={1.5} 
      stroke="currentColor"
      width={width}
      height={height}
      className={className}
      {...props}
    >
      <path 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        d="M6 18L18 6M6 6l12 12"   
      />
    </svg>
  )
}
XMark.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
}

XMark.defaultProps = {
  color: "#000000",
  className: "",
  width: "16px",
  height: "16px",
}

export default XMark;