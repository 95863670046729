import React from "react"
import styled from "styled-components"
import Form from "./form"
import Result from "./result"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`

const Tagging = (props) => {
  return (
    <StyledContainer
      color="#9F7AEA"
    >
      <Form
        postTextTagging={props.postTextTagging}
        response={props.response}
        params={props.queryParams}
        textTaggingResult={props.textTaggingResult}
      />
      {props.queryParams?.key ?
        (
          <Result 
            getTextTagging={props.getTextTagging}
            textTaggingResult={props.textTaggingResult}
            email={props.queryParams.email}
            jobKey={props.queryParams.key}
          />
        )
        :
        (
          ''
        )
      }
    </StyledContainer>
  )
}


export default Tagging;